import { environment } from "../../environments/environment";
import { AccountStatus } from "./account-status-enum";
import { SubscriptionProvider } from "./subscription-provider-enum";
import { LocationBean, StringMap } from "./types";

export const SubscriptionPrice: {[key: string]: number} = {
  ONE_MONTH: environment.pricingData.ONE_MONTH,
  TWO_MONTHS: 569.00,
  THREE_MONTHS: 849.00,
  SIX_MONTHS: environment.pricingData.SIX_MONTHS,
  TWELVE_MONTHS: environment.pricingData.TWELVE_MONTHS,
};

export enum SubscriptionOption {
  ONE_MONTH = "ONE_MONTH",
  TWO_MONTHS = "TWO_MONTHS",
  THREE_MONTHS = "THREE_MONTHS",
  SIX_MONTHS = "SIX_MONTHS",
  TWELVE_MONTHS = "TWELVE_MONTHS",
}

export enum SubscriptionOptionMonths {
  ONE_MONTH = 1,
  TWO_MONTHS = 2,
  THREE_MONTHS = 3,
  SIX_MONTHS = 6,
  TWELVE_MONTHS = 12,
}

export const SubscriptionStripeProductId: {[key: string]: string} = {
  ONE_MONTH: environment.stripe.products.ONE_MONTH,
  SIX_MONTHS: environment.stripe.products.SIX_MONTHS,
  TWELVE_MONTHS: environment.stripe.products.TWELVE_MONTHS,
};


export interface UpdateUserCommand {
  userId: string;
  email: string;
  name: string;
  picture: string;
  cellPhone: string;
  language: string;
  termOfServiceAgree: boolean;
  notifiableFromPromo: boolean;
  notifiableFromHubs: boolean;
  notifiableFromClients: boolean;
  locations: LocationBean[];
  tags: string[];
}

export interface UserEntity extends UpdateUserCommand {
  active: boolean;
  subType: AccountStatus;
  subLastRenew: string;
  subValidUntil: string;
  subExpired: boolean;
  createdOn: string;
  updatedOn: string;
  subProvider: SubscriptionProvider;
}

export interface CreateSubscriptionRequestCommand {
  userId: string;
  subscriptionOption: SubscriptionOption;
  hubId: string;
}

export interface SubscriptionRequestEntity
  extends CreateSubscriptionRequestCommand {
  id: string;
  payload: StringMap;
}
